<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Roles/View</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h4 class="text-center">{{ role.name }}</h4>
            <div class="text-center"><span class="text-center badge badge-info">{{ role.type }}</span></div>
            <p class="text-center mt-1 mb-1 ">Description: {{ role.description ?? "Not Provided" }}</p>
            <div class="bt-1">
              <h3 class="py-2 d-flex align-items-center">Assigned Permissions</h3>
              <div class="row">
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 "
                     v-for="(singlePermissionGroup,index) in role.permissions" :key="index">
                  <div class="card bg-white">
                    <h5>{{ singlePermissionGroup.permission_group.name }}</h5>
                    <div class="">
                      <div v-for="(singlePermission,index) in singlePermissionGroup.permissions" :key="index"
                           class="mb-1">
                        <div class="mb-md-0 mb-1"
                             :class="[singlePermission.is_assign === true ? 'badge badge-pill badge-success' : 'badge badge-pill badge-danger']">
                          {{ singlePermission.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
    </template>
  </AppLayout>
</template>

<script>
import Loader from "@/components/backEnd/mixins/Loader";
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import {
  mapActions,
  mapGetters
} from 'vuex'

export default {
  name: "ViewRoles",
  components: {
    AppLayout,

    // pagination
  },
  mixins: [Loader],
  data() {
    return {
      getPermissionParams: {
        with_relation: ['permissions'],
      },

    }

  },
  methods: {
    ...mapActions({
      getRole: 'appRoles/getRole',
      commitNotFoundRouteStatus: 'commitNotFoundRouteStatus',
    }),
    async getSingleRole() {
      let paramObj = {
        id: this.$route.params.id,
        params: this.getPermissionParams,
      }
      await this.getRole(paramObj).then(async (response) => {
        if (response.status === 404) {
          this.commitNotFoundRouteStatus(true);
          return;
        }
        if (response.status !== 200) {
          this.showToastMessage(response);
        }
      });

    },

  },
  computed: {
    ...mapGetters({
      role: 'appRoles/role',
      previousRoute: 'previousRoute',

    }),
    roleType() {
      return [{
        value: 0,
        name: 'Customer'
      },
        {
          value: 1,
          name: 'Admin'
        },
        {
          value: 2,
          name: 'Technician'
        },
        {
          value: 3,
          name: 'Manager'
        },
        {
          value: 4,
          name: 'Lab User'
        },
        {
          value: 5,
          name: 'Customer Care'
        },
        {
          value: 6,
          name: 'Vendor'
        },
      ]
    },

  },
  async mounted() {
    await this.loader(true);
    await this.getSingleRole();
    this.loader(false);
  },

}
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.table td {
  vertical-align: middle;
  border-bottom: 1px solid #DFE3E7;
  border-top: 1px solid #DFE3E7;;
}

h5 {
  padding-bottom: 0;
}
</style>
